/*!

 =========================================================
 * Paper Kit PRO React v1.2.0 based on Paper Kit 2 Pro - v2.3.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/paper-kit-2-pro
 * Copyright 2020 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import "paper-kit/variables";
@import "paper-kit/mixins";

// Plugins CSS
// @import 'paper-kit/plugins/plugin-bootstrap-switch';
// @import 'paper-kit/plugins/plugin-nouislider';
// @import 'paper-kit/plugins/plugin-bootstrap-select';
// @import 'paper-kit/plugins/plugin-tagsinput';
// @import 'paper-kit/plugins/plugin-fileupload';
// @import 'paper-kit/plugins/plugin-datetimepicker';

// Core CSS
// @import 'paper-kit/nucleo-outline';
// @import 'paper-kit/alerts';
// @import 'paper-kit/checkbox-radio';
// @import 'paper-kit/badges';
// @import 'paper-kit/pagination';
@import "paper-kit/typography";
@import "paper-kit/misc";
// @import 'paper-kit/misc-extended';
@import "paper-kit/buttons";
// @import 'paper-kit/social-buttons';
// @import 'paper-kit/inputs';
// @import 'paper-kit/progress-bars';
// @import 'paper-kit/tooltips-and-popovers';

// components
// @import 'paper-kit/tabs-navs';
// @import 'paper-kit/navbars';
// @import 'paper-kit/navbar-extend';
// @import 'paper-kit/dropdown-extend';
// @import 'paper-kit/images';
// @import 'paper-kit/tags';
// @import 'paper-kit/animated-buttons';
// @import 'paper-kit/preloaders';
// @import 'paper-kit/tables';
// @import 'paper-kit/info-areas';
// @import 'paper-kit/media';
// @import 'paper-kit/modal';
// @import 'paper-kit/carousel';
@import "paper-kit/footers";
@import "paper-kit/headers";
// @import 'paper-kit/collapse';

// cards
@import "paper-kit/cards";
// @import 'paper-kit/cards/card-background';
// @import 'paper-kit/cards/card-blog';
// @import 'paper-kit/cards/card-contact';
// @import 'paper-kit/cards/card-image';
// @import 'paper-kit/cards/card-register';
// @import 'paper-kit/cards/card-plain';
// @import 'paper-kit/cards/card-pricing';
@import "paper-kit/cards/card-profile";
// @import 'paper-kit/cards/card-product';
// @import 'paper-kit/cards/card-signup';
// @import 'paper-kit/cards/card-testimonials';

// For gallery, using PhotoSwipe
// @import 'paper-kit/plugins/photo-swipe/main.scss';
// @import 'paper-kit/plugins/photo-swipe/default-skin.scss';
// @import 'paper-kit/plugins/photo-swipe/gallery.scss';
// @import 'paper-kit/plugins/photo-swipe/main.scss';

// Isometric cards plugin
// @import 'paper-kit/plugins/plugin-isometricGrids';

// example pages and sections
@import "paper-kit/examples";
@import "paper-kit/sections";
@import "paper-kit/sections-extend";

@import "paper-kit/responsive";

// Nucleo Icons
@import "paper-kit/nucleo-icons";

// React Differences
// @import 'react/react-differences';

// html {
//   -webkit-text-size-adjust: inherit !important;
// }

* {
  box-sizing: border-box;
}

/* Set a background color */
body {
  font-family: Helvetica, sans-serif;
}

.section-blue {
  background: #346494;

  h1,
  h2,
  label {
    color: white;
  }
}

.card-description {
  color: black !important;
}

/* The actual timeline (the vertical ruler) */
.s-timeline {
  position: relative;
  width: 50%;
  margin: 0 auto;

  h2 {
    margin-bottom: 16px;
    hyphens: auto;
  }
}

/* The actual timeline (the vertical ruler) */
.s-timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #346494;
  top: 0;
  bottom: 0;
  left: 0;
}

/* Container around content */
.s-container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 100%;
}

/* The circles on the timeline */
.s-container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  // right: -17px;
  background-color: white;
  border: 4px solid #346494;
  top: 71px;
  left: -10px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* The actual content */
.s-content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
}

.s-container.left.last {
  padding-bottom: 100px;
}

.ergo-hp-link {
  background: white;
  color: #66615b;
  margin-top: 1rem;
}

.page-header {
  .mobile-placeholder {
    display: none;
    background: #346494;
  }

  .img-container {
    display: flex;
    &--max {
      transform: scale((1.05));
    }
  }
}

.center-container {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the container perfectly */
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center;     /* Vertical centering */
  width: 100%;
  z-index: 210;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 700px) {
  .content-center {
    display: none;
  }

  html {
    overflow-x: hidden !important;
  }
  .s-timeline {
    width: 100%;
    left: 16px;
  }

  /* Full-width containers */
  .s-container {
    width: 100%;
  }

  .page-header {
    .img-container {
      display: block;

      img {
        width: 100% !important;
      }

      &--max {
        transform: scale(1.13);
      }

      .mobile-placeholder {
        display: block;
        padding-top: 4rem;
        padding-bottom: 2rem;

        img {
          width: 65% !important;
          margin: 0 auto;
          display: block;
        }
      }
    }

    .content-center {
      top: 86%;

      .title {
        img {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .motto .btn-lg{
    font-size: 14px !important;
    padding: 4px 10px !important;
  }
}


//end
